import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useAddress, useWeb3Context } from "./hooks/web3Context";
import { Box } from "@material-ui/core";

import TopBar from "./components/TopBar/TopBar";
//import Landing from "./views/Landing/Landing";
import Footer from "./components/Footer/Footer";
import { ethers } from "ethers";

import "./style.scss";
import Notification from "./components/Notification";
import styled from "styled-components";
import { multicall } from "./utils/contracts";
import { getNetworkConfig } from "./config/config";
import ERC20ABI from "./abis/ERC20ABI.json";
import TimelockABI from "./abis/TimelockABI.json";
import Spinner from "./components/Spinner/Spinner";
import { getChainFromLocalStorage } from "./hooks/useChainProvider";
const AdminView = React.lazy(() => import("./views/AdminView/AdminView"));
const LockManagement = React.lazy(() =>
  import("./components/LPBox2/LockManagement")
);
const LockedStackingAdmin = React.lazy(() =>
  import("./views/LockedStackingAdmin")
);
const Landing = React.lazy(() => import("./views/Landing/Landing"));

let poolid = null,
  tokenid = null;

const configs = getNetworkConfig(getChainFromLocalStorage());

function App() {
  const { connect, hasCachedProvider, connected } = useWeb3Context();
  const address = useAddress();
  const account = useAddress();

  const [notification, setNotification] = useState(null);
  const [referer, setReferer] = useState(null);
  const [isAdminAccount, setIsAdminAccount] = useState(false);
  const [poolinfo, setPoolInfo] = useState([{}, {}, {}]);
  const [tokeninfo, setTokenInfo] = useState({
    decimals: configs.tokenDecimals,
    balance: 0,
    allowance: false,
    price: 0,
  });
  const [price, setPrice] = useState(0);

  const [pending, setPending] = useState(true);

  async function fetchData() {
    fetchTokenInfo();
    fetchPoolInfo();
  }

  async function fetchTokenInfo() {
    if (account) {
      let calls = [
        {
          address: configs.tokenAddr,
          name: "decimals",
          params: [],
        },
        {
          address: configs.tokenAddr,
          name: "balanceOf",
          params: [account],
        },
        {
          address: configs.tokenAddr,
          name: "allowance",
          params: [account, configs.lockingAddr],
        },
      ];
      multicall(ERC20ABI, calls)
        .then((result) => {
          setTokenInfo({
            decimals: result[0][0],
            balance: result[1][0],
            allowance:
              result[2][0] / 1 > ethers.utils.parseEther("10000000")
                ? true
                : false,
          });
        })
        .catch((error) => console.log(error));
    } else {
      setTokenInfo({
        decimals: configs.tokenDecimals,
        balance: 0,
        allowance: false,
      });
    }
    // axios.get(`https://api.pancakeswap.info/api/v2/tokens/${TOKEN_ADDR}`).then(price => {
    //   const _price = price.data.data.price;
    //   setPrice(_price);
    // });
    setPrice(0.1);
  }

  async function fetchPoolInfo() {
    let temp = [];
    console.log("configs.lockingAddr", configs.lockingAddr);
    let calls = [
      {
        address: configs.lockingAddr,
        name: "getTotalLockValue",
        params: [],
      },
    ];
    if (account) {
      calls.push({
        address: configs.lockingAddr,
        name: "getLocksForUser",
        params: [account],
      });

      calls.push({
        address: configs.lockingAddr,
        name: "isAdminWallet",
        params: [account],
      });
    }

    const result = await multicall(TimelockABI, calls);
    temp.push({
      overallLocked: ethers.utils.formatUnits(
        result[0][0].toString(),
        configs.tokenDecimals
      ), //to be available
      userLocks: account ? result[1][0] : 0,
    });

    if (result && result[2] && result[2][0]) {
      setIsAdminAccount(result[2][0]);
    }
    setPoolInfo(temp);
    console.log(temp);
    setPending(false);
  }

  const getTotalStakeAmount = async (cb) => {
    if (account) {
      let calls = [
        {
          address: configs.lockingAddr,
          name: "getTotalStakedAmount",
          params: [account],
        },
      ];

      multicall(TimelockABI, calls)
        .then((result) => {
          let totalStakedAmount = ethers.utils.parseUnits(
            result[0][0].toString(),
            configs.tokenDecimals
          );
          cb(totalStakedAmount);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    setPending(true);
    fetchData();
    if (tokenid) clearInterval(tokenid);
    tokenid = setInterval(function () {
      fetchData();
    }, 5000);

    return () => {
      clearInterval(tokenid);
    };
  }, [account, connected]);

  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then((msg) => {
        if (msg.type === "error") {
          setNotification(msg);
        }
      });
    } else {
      // then user DOES NOT have a wallet
    }

    // We want to ensure that we are storing the UTM parameters for later, even if the user follows links
  }, []);

  return (
    <Router>
      <StyledContainer>
        <TopBar
          setNotification={setNotification}
          account={address}
          isAdminAccount={isAdminAccount}
        />

        <Switch>
          <Route exact path={["/", "/referral/:referer_id"]}>
            <React.Suspense fallback={<Spinner />}>
              <Landing
                account={address}
                setNotification={setNotification}
                poolinfo={poolinfo}
                price={price}
                tokeninfo={tokeninfo}
                fetchData={fetchData}
                fetchingPending={pending}
                referer={referer}
                setReferer={setReferer}
                getTotalStakeAmount={getTotalStakeAmount}
              />
            </React.Suspense>
          </Route>

          {isAdminAccount && (
            <Route exact path="/my-dashboard">
              <React.Suspense fallback={<Spinner />}>
                <AdminView
                  account={account}
                  isAdminAccount={isAdminAccount}
                  // getReferralEarnings={getReferralEarningsMeta}
                  setNotification={setNotification}
                  pool={poolinfo}
                />
              </React.Suspense>
            </Route>
          )}
          {/*  Lock management dashboard */}
          <Route exact path="/lock-management">
            <React.Suspense fallback={<Spinner />}>
              <LockManagement />
            </React.Suspense>
          </Route>
          <Route exact path="/lock-management-admin-dashboard">
            <React.Suspense fallback={<Spinner />}>
              <LockedStackingAdmin />
            </React.Suspense>
          </Route>
        </Switch>
        <Notification data={notification} />
      </StyledContainer>
      <Footer poolinfo={poolinfo[0]} />
    </Router>
  );
}

const StyledContainer = styled(Box)`
  background-color: rgb(247, 247, 247);
  width: 100%;
  min-height: 100vh;
  background-size: 100% 100%;
  background-position: top center;
  @media screen and (min-width: 1920px) {
    background-size: 100% 100%;
  }
`;
export default App;
