import React, { createContext, useEffect, useState } from "react";
import { useWeb3Context } from "../hooks/web3Context";
import { DENOMINATOR, getNetworkConfig } from "../config/config";
import { getERC20Contract, LpLockedStakingContract } from "../utils/contracts";
import { ethers } from "ethers";
import useIsMountedRef from "../hooks/useIsMounted";
import { logInfo } from "../utils/logger";
import { getChainFromLocalStorage } from "../hooks/useChainProvider";

const configs = getNetworkConfig(getChainFromLocalStorage());

const LPLockedStackingContext = createContext({
  lockPeriod: null,
  totalLocked: null,
  apy: null,
  rewardsPending: null,
  userLocks: null,
  userTotalDeposit: null,
  allowance: null,
  balance: null,
  isAdmin: null,
  totalPendingRewards: null,
  approve: (amount) => {},
  lock: (amount) => {},
  emergencyWithdraw: (depositId) => {},
  unlockWithPenalty: (depositId) => {},
  unlockAndClaim: (depositId) => {},
  changeAPY: async (newAPY) => {},
});

const getAPY = async (signer) => {
  logInfo("here");
  const stakingContract = LpLockedStakingContract(signer, configs.farmAddress);
  const apy = await stakingContract.apy().catch((error) => logInfo(error));

  return apy ?? apy / DENOMINATOR;
};

const getUserTotalDeposit = async (signer, account) => {
  const stakingContract = LpLockedStakingContract(signer, configs.farmAddress);
  return await stakingContract.getTotalUserDeposit(account);
};

const getPoolInfo = async (signer) => {
  const stakingContract = LpLockedStakingContract(signer, configs.farmAddress);
  /*
          uint256 accERC20PerShare; // Accumulated ERC20s per share, times 1e36.
          uint256 stakedAmount; // Amount of @lpToken staked in this pool
          uint128 lockPeriod; // lock period in days
          uint128 lastRewardTime; // Last time where ERC20s distribution occurs.
       */
  const poolInfo = await stakingContract.poolInfo();

  return {
    totalStaked: poolInfo.stakedAmount,
    lockPeriod: poolInfo.lockPeriod,
  };
};

const getUserLocks = async (signer, account) => {
  const stakingContract = LpLockedStakingContract(signer, configs.farmAddress);
  /*
      uint256 amount;
      uint256 depositTime;
      uint256 rewardDebt;
       */
  const _deposits = await stakingContract.getUserDeposits(account);

  return await Promise.all(
    _deposits.map(async (deposit) => {
      const penalty = await stakingContract.getPenalty(deposit.depositTime);

      const depositInfo = {
        amount: deposit.amount,
        depositTime: deposit.depositTime,
        rewardDebt: deposit.rewardDebt,
        penalty: penalty / DENOMINATOR,
      };
      return depositInfo;
    })
  );
};

const getAllowance = async (signer, account) => {
  logInfo("allowance called");
  logInfo("configs.lpAddress", configs.lpAddress);
  const lpContract = getERC20Contract(signer, configs.lpAddress);
  return await lpContract.allowance(account, configs.farmAddress);
};

const getBalance = async (signer, account) => {
  const lpContract = getERC20Contract(signer, configs.lpAddress);
  return await lpContract.balanceOf(account);
};

const approveCall = async (signer) => {
  if (!signer) throw "Signer is not defined";
  const lpContract = getERC20Contract(signer, configs.lpAddress);
  return await lpContract.approve(
    configs.farmAddress,
    ethers.constants.MaxUint256
  );
};

const lockCall = async (signer, amount, lockPeriod) => {
  if (!signer) throw "Signer is not defined";
  const stakingContract = LpLockedStakingContract(signer, configs.farmAddress);
  return await stakingContract.deposit(amount);
};

const unlockAndClaimCall = async (signer, depositId) => {
  if (!signer) throw "Signer is not defined";
  const stakingContract = LpLockedStakingContract(signer, configs.farmAddress);
  return await stakingContract.unstakeUnlockedDeposit(depositId);
};

const unlockWithPenaltyCall = async (signer, depositId) => {
  if (!signer) throw "Signer is not defined";
  const stakingContract = LpLockedStakingContract(signer, configs.farmAddress);
  return await stakingContract.unstakeWithPenalty(depositId);
};

const emergencyWithdrawCall = async (signer, depositId) => {
  if (!signer) throw "Signer is not defined";
  const stakingContract = LpLockedStakingContract(signer, configs.farmAddress);
  return await stakingContract.emergencyWithdraw(depositId);
};

const getIsAdmin = async (signer, account) => {
  const stakingContract = LpLockedStakingContract(signer, configs.farmAddress);
  return await stakingContract.isAdmin(account);
};

const getTotalRewardsPending = async (signer) => {
  const stakingContract = LpLockedStakingContract(signer, configs.farmAddress);
  return await stakingContract.totalPending();
};

export const LPLockedStackingProvider = ({ children }) => {
  const {
    connect,
    disconnect,
    hasCachedProvider,
    provider,
    connected,
    address,
    chainID,
    web3Modal,
    account,
  } = useWeb3Context();
  const isMounted = useIsMountedRef();
  const [lockPeriod, setLockPeriod] = useState(undefined);
  const [totalLocked, setTotalLocked] = useState(undefined);
  const [apy, setAPY] = useState(undefined);
  const [rewardsPending, setRewardsPending] = useState(undefined);
  const [allowance, setAllowance] = useState(undefined);
  const [balance, setBalance] = useState(undefined);
  const [totalPendingRewards, setTotalPendingRewards] = useState(undefined);
  /**
   * @type {Array<{amount: number, depositTime: number, rewardDebt: number, penalty: number}>}
   */
  const [userLocks, setUserLocks] = useState(undefined);
  const [userTotalDeposit, setUserTotalDeposit] = useState(undefined);
  const [isAdmin, setIsAdmin] = useState(undefined);

  useEffect(() => {
    logInfo("account", account);
    logInfo("address", address);
    logInfo("chainID === configs.chainID", chainID === configs.chainID);
    getAPY()
      .then((reslt) => {
        logInfo("apy", reslt);
        if (isMounted.current) setAPY(reslt);
      })
      .catch((error) => logInfo(error));

    getPoolInfo()
      .then(({ totalStaked, lockPeriod }) => {
        logInfo("totalStaked", totalStaked);
        logInfo("lockPeriod", lockPeriod.toString());
        if (isMounted.current) {
          setLockPeriod(lockPeriod);
          setTotalLocked(totalStaked);
        }
      })
      .catch((error) => logInfo(error));

    getTotalRewardsPending().then((reslt) => {
      logInfo("totalRewardsPending", reslt);
      if (isMounted.current) setTotalPendingRewards(reslt);
    });

    if (connected && address && chainID === configs.chainID) {
      logInfo("connected");
      logInfo("address", address);
      logInfo("provider", provider);
      const signer = provider.getSigner();
      logInfo("signer", signer);

      fetchLocks().catch((error) => logInfo(error));

      logInfo("fetching allowance");
      fetchAllowance().catch((error) =>
        logInfo("error fetchAllowance", error.message)
      );

      fetchBalance().catch((error) => logInfo(error));

      getIsAdmin(signer, address).then((isAdmin) => {
        logInfo("isAdmin", isAdmin);
        setIsAdmin(isAdmin);
      });
    }
  }, [address, connected, chainID]);

  const fetchAllowance = async () => {
    logInfo(
      "connected && address && chainID === configs.chainID",
      connected && address && chainID === configs.chainID
    );
    if (connected && address && chainID === configs.chainID) {
      logInfo("fetching allowance");
      getAllowance(undefined, address)
        .then((allowance) => {
          logInfo("allowance", allowance);
          if (isMounted.current) setAllowance(allowance);
        })
        .catch((error) => logInfo(error));
    }
  };

  const fetchLocks = async () => {
    if (connected && address && chainID === configs.chainID) {
      getUserLocks(undefined, address).then((deposits) => {
        logInfo("deposits", JSON.stringify(deposits));
        if (isMounted.current) setUserLocks(deposits);
      });
      getUserTotalDeposit(undefined, address).then((totalDeposit) => {
        logInfo("totalDeposit", totalDeposit);
        if (isMounted.current) setUserTotalDeposit(totalDeposit);
      });
    }
  };

  const fetchBalance = async () => {
    if (connected && address && chainID === configs.chainID) {
      getBalance(undefined, address).then((balance) => {
        logInfo("balance", balance);
        if (isMounted.current) setBalance(balance);
      });
    }
  };

  const approve = async () => {
    if (connected && address && chainID === configs.chainID) {
      const tx = await approveCall(provider.getSigner(address), address);
      await tx.wait();
      await fetchAllowance();
    }
  };

  const lock = async (amount) => {
    logInfo("lock in: ", amount);
    if (connected && address && chainID === configs.chainID) {
      logInfo(lockPeriod);
      const tx = await lockCall(
        provider.getSigner(address),
        amount.toString(),
        lockPeriod.toString()
      );
      await tx.wait();
      await fetchLocks();
      await fetchBalance();
    }
  };

  const unlockAndClaim = async (depositId) => {
    if (connected && address && chainID === configs.chainID) {
      const tx = await unlockAndClaimCall(
        provider.getSigner(address),
        depositId
      );
      await tx.wait();
      await fetchLocks();
      await fetchBalance();
    }
  };

  const unlockWithPenalty = async (depositId) => {
    if (connected && address && chainID === configs.chainID) {
      const tx = await unlockWithPenaltyCall(
        provider.getSigner(address),
        depositId
      );
      await tx.wait();
      await fetchLocks();
      await fetchBalance();
    }
  };

  const emergencyWithdraw = async (depositId) => {
    if (connected && address && chainID === configs.chainID) {
      const tx = await emergencyWithdrawCall(
        provider.getSigner(address),
        depositId
      );
      await tx.wait();
      await fetchLocks();
      await fetchBalance();
    }
  };

  const changeAPY = async (newAPY) => {
    const signer = provider.getSigner();
    const stakingContract = LpLockedStakingContract(
      signer,
      configs.farmAddress
    );
    const tx = await stakingContract.setApy(newAPY);
    await tx.wait();
    getAPY()
      .then((reslt) => {
        logInfo("apy", reslt);
        if (isMounted.current) setAPY(reslt);
      })
      .catch((error) => logInfo(error));
  };
  return (
    <LPLockedStackingContext.Provider
      value={{
        lockPeriod,
        totalLocked,
        apy,
        rewardsPending,
        userLocks,
        userTotalDeposit,
        allowance,
        balance,
        isAdmin,
        totalPendingRewards,
        approve,
        lock,
        emergencyWithdraw,
        unlockWithPenalty,
        unlockAndClaim,
        changeAPY,
      }}
    >
      {children}
    </LPLockedStackingContext.Provider>
  );
};

export const LPLockedStackingConsumer = LPLockedStackingContext.Consumer;

export default LPLockedStackingContext;
