export const supportedChains = [1, 56];

// LP-LOCKED-STACKING
export const CONFIGS = {
  bsc: {
    startBlock: 24053236,
    tokenDecimals: 18,
    rpcURL: "https://bsc-dataseed.binance.org/",
    chainID: 56,
    chainName: "Binance Smart Chain",
    chainCurrency: "BNB",
    blockExplorer: "https://bscscan.com/",
    tokenAddr: "0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64",
    tokenName: "Link Bridge",
    tokenSymbol: "LNKB",
    lockingAddr: "0xA0aF634Cb48fdb378605C457FF38C136DA64A567",
    multicallAddr: "0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb",
    lockableAmount: "500",
    tokenWebsite: "https://linkbridge.app/",
    tokenContractLink:
      "https://bscscan.com/token/0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64#code",
    tokenSwapLink:
      "https://pancakeswap.finance/swap?outputCurrency=0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64",
    tokenChartLink:
      "https://dexscreener.com/bsc/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64",
    lockingPlans: [
      {
        duration: "365 days",
      },
    ],
    referralPlans: {
      1: 3,
      2: 2,
      3: 1,
    },
    farmAddress: "0xf5E827760d27CF7973C323909733DAB938a8376b",
    lnkbAddress: "0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64",
    lpAddress: "0x494fBE63b342D279C3a6b286FFc49c325f17BDc4",
    addLPUrl:
      "https://pancakeswap.finance/add/BNB/0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64",
    lpSymbol: "LNKB-BNB LP",
    lpDecimals: 18,
  },

  goerli: {
    startBlock: 8122728,
    tokenDecimals: 18,
    rpcURL: "https://rpc.ankr.com/eth_goerli",
    chainID: 5,
    chainName: "Goerli",
    chainCurrency: "ETH",
    blockExplorer: "https://goerli.etherscan.io/",
    tokenAddr: "0x27bF771A5df6B6E61d72a5313269aD878f4e1154",
    tokenName: "Link Bridge",
    tokenSymbol: "LNKB",
    lockingAddr: "0x4726Ddf3d54Bd7Fc6E0DAa8aA7E8DC4d2875c224",
    multicallAddr: "0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e",
    lockableAmount: "500",
    tokenWebsite: "https://linkbridge.app/",
    tokenContractLink:
      "https://bscscan.com/token/0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64#code",
    tokenSwapLink:
      "https://pancakeswap.finance/swap?outputCurrency=0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64",
    tokenChartLink:
      "https://dexscreener.com/bsc/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64",
    lockingPlans: [
      {
        duration: "365 days",
      },
    ],
    referralPlans: {
      1: 3,
      2: 2,
      3: 1,
    },
    farmAddress: "0x89B3BdE7F8f4D844DB91FE1bDF869B28187918aE",
    lnkbAddress: "0x27bF771A5df6B6E61d72a5313269aD878f4e1154",
    lpAddress: "0x93B4576FA7A23A246d0656D7EAccD5049ad2b4aA",
    addLPUrl:
      "https://pancakeswap.finance/add/BNB/0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64",
    lpSymbol: "LNKB-BNB LP",
    lpDecimals: 18,
  },

  bscTestNet: {
    tokenDecimals: 18,
    rpcURL: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    chainID: 97,
    blockExplorer: "https://testnet.bscscan.com/",
    tokenAddr: "0xF2eCAbbdCBc90903976bD301866aC134F6cF5287",
    tokenName: "Link Bridge",
    tokenSymbol: "LNKB",
    lockingAddr: "0x87327112FADF2ab43797bFE659e2870d25356F3a",
    multicallAddr: "0x47ad8695829627D47d71d3b480a9A5d047A51Fe9",
    lockableAmount: "500",
    tokenWebsite: "https://linkbridge.app/",
    tokenContractLink:
      "https://etherscan.io/address/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64",
    tokenSwapLink:
      "https://app.uniswap.org/#/swap?inputCurrency=0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64",
    tokenChartLink:
      "https://dexscreener.com/ethereum/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64",
    lockingPlans: [
      {
        duration: "365 days",
      },
    ],
    referralPlans: {
      1: 3,
      2: 2,
      3: 1,
    },
    farmAddress: "0x54b6E663f8e69aa2B8eC42e3c4d817f9AaAA6540",
    lnkbAddress: "0xF2eCAbbdCBc90903976bD301866aC134F6cF5287",
    lpAddress: "0x20Ae2394e63E0c2560bB9Fa12DAAaeFEeC250f23",
    addLPUrl:
      "https://app.uniswap.org/#/add/v2/ETH/0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64",
    lpSymbol: "LNKB-ETH LP",
    lpDecimals: 18,
  },
  mainnet: {
    startBlock: 15898766,
    tokenDecimals: 18,
    rpcURL: "https://cloudflare-eth.com/",
    chainID: 1,
    chainName: "Ethereum",
    chainCurrency: "ETH",
    blockExplorer: "https://etherscan.io/",
    tokenAddr: "0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64",
    tokenName: "Link Bridge",
    tokenSymbol: "LNKB",
    lockingAddr: "0x99C7f389C5C394f9bE0e90Ec3086105f44DcC229",
    multicallAddr: "0x7fd8a3fD7D81810C02f466782C829feCD042c016",
    lockableAmount: "500",
    tokenWebsite: "https://linkbridge.app/",
    tokenContractLink:
      "https://etherscan.io/address/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64",
    tokenSwapLink:
      "https://app.uniswap.org/#/swap?inputCurrency=0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64",
    tokenChartLink:
      "https://dexscreener.com/ethereum/0xaefda5c5dd0d1c607523f3e0ef3ea3e4ec8c2c64",
    lockingPlans: [
      {
        duration: "365 days",
      },
    ],
    referralPlans: {
      1: 3,
      2: 2,
      3: 1,
    },
    // TODO change this
    farmAddress: "0xf5f5C97af211e2ED7F0369B3B27fAb9FD171A8F6",
    lnkbAddress: "0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64",
    lpAddress: "0x01852392094997957B99694D6a03B7b72C4d518c",
    addLPUrl:
      "https://app.uniswap.org/#/add/v2/ETH/0xAefDA5C5DD0D1C607523f3e0eF3ea3E4EC8c2C64",
    lpSymbol: "LNKB-ETH LP",
    lpDecimals: 18,
  },
};

export const getNetworkConfig = (chain) => {
  const chains = {
    1: "mainnet",
    56: "bsc",
  };

  chain = chains[chain] || chains[1];
  const {
    startBlock,
    tokenDecimals,
    rpcURL,
    chainID,
    blockExplorer,
    tokenAddr,
    tokenName,
    tokenSymbol,
    lockingAddr,
    multicallAddr,
    lockableAmount,
    tokenWebsite,
    tokenContractLink,
    tokenSwapLink,
    tokenChartLink,
    lockingPlans,
    referralPlans,
    farmAddress,
    lnkbAddress,
    lpAddress,
    addLPUrl,
    lpSymbol,
    lpDecimals,
  } = CONFIGS[chain];

  return {
    startBlock,
    tokenDecimals,
    rpcURL,
    chainID,
    blockExplorer,
    tokenAddr,
    tokenName,
    tokenSymbol,
    lockingAddr,
    multicallAddr,
    lockableAmount,
    tokenWebsite,
    tokenContractLink,
    tokenSwapLink,
    tokenChartLink,
    lockingPlans,
    referralPlans,
    farmAddress,
    lnkbAddress,
    lpAddress,
    addLPUrl,
    lpSymbol,
    lpDecimals,
  };
};

export const DENOMINATOR = 10000;
