import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  SvgIcon,
  Typography,
  Popper,
  Paper,
  Divider,
  Link,
  Slide,
  Fade,
} from "@material-ui/core";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as CaretDownIcon } from "../../assets/icons/caret-down.svg";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import styled from "styled-components";
import { shorten } from "../../helpers";
import "../../config/colors.scss";

function ConnectMenu({ setNotification, account, bigType = false }) {
  const { connect, disconnect, connected, web3, chainID } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);

  let displayAccount = account
    ? account.slice(0, 5) + "..." + account.substr(account.length - 4)
    : "";
  let buttonText = "Connect Wallet";
  function onConnect() {
    connect().then((msg) => {
      if (msg.type === "error") {
        setNotification(msg);
      }
    });
  }

  if (isConnected) {
    buttonText = "Disconnect";
  }

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  return (
    <div className="wallet-menu" id="wallet-menu">
      <ConnectButton
        bigType={bigType}
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => (isConnected ? disconnect() : onConnect())}
        key={1}
      >
        <Box fontSize={"12px"}>{displayAccount}</Box>
        <Box>{buttonText}</Box>
      </ConnectButton>
    </div>
  );
}

export const ConnectButton = styled(Box)`
  ${({ bigType }) => (bigType ? "width: 100%; height: 48px" : "height: 48px")};
  align-items: center;
  border-radius: 4px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 1.2;
  justify-content: center;
  opacity: 1;
  padding: 0px 24px;
  text-align: center;
  outline: 0px;
  background-color: rgb(55, 111, 221);
  color: white;
  transition: all 0.3s;

  background: var(--button-backgroundColor);
  background-size: 300% 100%;

  :hover {
    background-position: 100% 0;
  }
`;

export default ConnectMenu;
