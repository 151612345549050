export const minutesAgo = (x: number) => {
  const now = new Date().getTime();
  return new Date(now - x * 60000).getTime();
};

export const getNumberWithCommas = (x: number) => {
  if (!x) return x;
  // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  return Number(x).toLocaleString();
};

export const formatDate = (someDateTimeStamp) => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var dt = new Date(someDateTimeStamp),
    date = dt.getDate(),
    diffDays = new Date().getDate() - date,
    diffMonths = new Date().getMonth() - dt.getMonth(),
    diffYears = new Date().getFullYear() - dt.getFullYear();

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return "Today";
  } else if (diffYears === 0 && diffDays === 1) {
    return "Yesterday";
  }

  const yyyy = dt.getFullYear();
  let mm: any = months[dt.getMonth()]; // Months start at 0!
  let dd: any = dt.getDate();

  if (dd < 10) dd = "0" + dd;

  return `${dd} ${mm} ${yyyy}`;
};
