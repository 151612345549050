import {
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import ConnectMenu, { ConnectButton } from "./ConnectMenu.jsx";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getNetworkConfig } from "../../config/config";

import "../../config/colors.scss";
import "./topbar.scss";
import useLPLockedStaking from "../../hooks/useLPLockedStaking";
import { getChainFromLocalStorage } from "../../hooks/useChainProvider";

const configs = getNetworkConfig(getChainFromLocalStorage());

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TopBar({ setNotification, account, isAdminAccount }) {
  const [hamburgeropen, setHamburgerOpen] = useState(false);
  const sm = useMediaQuery("(max-width : 500px)");
  const dialog = useRef();
  const hamburger = useRef();
  const lockedStackingContext = useLPLockedStaking();

  const classes = useStyles();
  const [state, setState] = useState({
    age: "",
    name: "hai",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  useEffect(() => {
    document.addEventListener("mouseup", function (event) {
      if (
        dialog.current &&
        !dialog.current.contains(event.target) &&
        !hamburger.current.contains(event.target)
      ) {
        setHamburgerOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    //console.log(hamburgeropen)
  }, [hamburgeropen]);
  return (
    <StyledContainer>
      <Box
        className="topbar"
        display={"flex"}
        justifyContent={"space-between"}
        pr={"16px"}
        pl={"16px"}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Link to="/" className="nav-item">
            <Box display={"flex"} alignItems={"center"}>
              <Box mr={"12px"} mt={"12px"}>
                <img
                  className="logo"
                  src={"/logo.svg"}
                  width={"130px"}
                  height={"100%"}
                  alt={"logo"}
                />
              </Box>
              <Box
                className="header-textColor"
                ml={"5px"}
                mt={"7px"}
                fontSize={"22px"}
                fontWeight={800}
                fontFamily={'"Rubik", Sans-serif'}
                display={sm ? "none" : "block"}
              >
                LinkBridge (${configs.tokenSymbol}) Locking
              </Box>
            </Box>
          </Link>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          {account && isAdminAccount && (
            <Box className="nav-bar">
              <Box display={"flex"} alignItems={"center"} height={"64px"}>
                <Link to="/my-dashboard" className="nav-item">
                  Dashboard
                </Link>
              </Box>
            </Box>
          )}
          {account && lockedStackingContext.isAdmin && (
            <Box className="nav-bar">
              <Box display={"flex"} alignItems={"center"} height={"64px"}>
                <Link
                  to="/lock-management-admin-dashboard"
                  className="nav-item"
                >
                  Locked LP Dashboard
                </Link>
              </Box>
            </Box>
          )}
          <Box display={"flex"} alignItems={"center"} height={"64px"}>
            <ConnectMenu setNotification={setNotification} account={account} />
          </Box>
        </Box>
      </Box>
    </StyledContainer>
  );
}
const StyledContainer = styled(Box)`
  width: 100%;
  background-color: var(--header-backgroundColor);
  padding: 0 20px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  a {
    cursor: pointer;
    text-decoration: none;
  }
  @media screen and (max-width: 450px) {
    padding: 0;
  }
  @media screen and (max-width: 1175px) {
    > div:nth-child(1) > div:nth-child(1) > div {
      justify-content: start;
      > div {
        width: fit-content;
        margin-left: 10px;
      }
    }
  }
  position: fixed;
  top: 0;
  z-index: 10;
`;

export default TopBar;
