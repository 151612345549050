import { useCallback, useEffect, useState } from "react";

export const saveChainToLocalStorage = (chain) => {
  localStorage.setItem("chain", chain);
  window.location.reload();
};
const supportedChains = [1, 56];
export const getChainFromLocalStorage = () => {
  const savedChain = localStorage.getItem("chain")
    ? localStorage.getItem("chain")
    : 1;
  const valid = supportedChains.includes(Number(savedChain));
  return valid ? Number(savedChain) : 1;
};

const useChainProvider = (callback, deps) => {
  const [chainId, setChainId] = useState(null);

  useEffect(() => {
    const chain = getChainFromLocalStorage();
    setChainId(Number(chain));
  }, []);

  const setSelectedChain = useCallback((chain) => {
    saveChainToLocalStorage(chain);
    setChainId(chain);
  }, deps);

  return {
    chainId,
    setSelectedChain,
  };
};

export default useChainProvider;
