/* eslint-disable global-require */
import { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Web3ContextProvider } from "./hooks/web3Context";
import React from "react";

import App from "./App";
import { LPLockedStackingProvider } from "./contexts/LPLockedStackingContext";

export default class Root extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Web3ContextProvider>
        <LPLockedStackingProvider>
          <BrowserRouter basename={"/#"}>
            <App />
          </BrowserRouter>
        </LPLockedStackingProvider>
      </Web3ContextProvider>
    );
  }
}
